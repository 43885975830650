<template>
    <b-navbar
        id="main-menu-navbar"
        :class="{ custom: isCustomHAF }"
    >
        <b-navbar-brand>
            <router-link :to="{ name: 'Home' }">
                <img
                    src="/logo.webp"
                    height="32"
                    width="130"
                    alt="pescario.ro"
                >
            </router-link>
        </b-navbar-brand>
        <div class="separator" />
        <ul>
            <language-item-navbar />
        </ul>
        <div
            id="nav-collapse"
            class="navbar-collapse collapse"
            is-nav
        >
            <ul
                v-if="!isCustomHAF"
                class="navbar-nav menu-left"
                @mouseleave="resetSubline()"
            >
                <li
                    class="nav-item dropdown-mega-menu"
                    @mouseover="activateMegaMenu()"
                    @mouseleave="deactivateMegaMenu()"
                >
                    <router-link to="">
                        {{ $t('header.navbar.products') }}
                    </router-link>
                    <desktop-mega-menu v-if="megaMenuIsActive" />
                </li>
                <li
                    class="nav-item"
                    @mouseover="subline($event, 'element')"
                >
                    <router-link :to="{ name: 'Catalog' }">
                        {{ $t('header.navbar.catalogs') }}
                    </router-link>
                </li>
                <li
                    class="subline"
                    :class="{ opened: sublineElement.opened }"
                    :style="sublineElement"
                />
                <li
                    class="nav-item"
                    @mouseover="subline($event, 'element')"
                >
                    <router-link :to="{ name: 'Voucher' }">
                        {{ $t('header.navbar.vouchers') }}
                    </router-link>
                </li>
                <li
                    class="subline"
                    :class="{ opened: sublineElement.opened }"
                    :style="sublineElement"
                />
            </ul>
            <ul
                v-if="!isCustomHAF"
                class="navbar-nav nav-search nav-drop-item"
            >
                <li class="form-inline">
                    <navbar-search />
                </li>
            </ul>
            <ul class="navbar-nav menu-right">
                <auth-item-navbar />
                <wishlist-item-navbar v-if="!isCustomHAF" />
                <cart-item-navbar v-if="!isCustomHAF" />
            </ul>
        </div>
    </b-navbar>
</template>
<script>
    import { mapState } from 'vuex';

    const AuthItemNavbar = () => import( /* webpackChunkName: "navbar-auth" */ './AuthItemNavbar');
    const CartItemNavbar = () => import( /* webpackChunkName: "navbar-cart" */ './CartItemNavbar');
    const LanguageItemNavbar = () => import( /* webpackChunkName: "navbar-language" */ './LanguageItemNavbar');
    const NavbarSearch = () => import( /* webpackChunkName: "navbar-search" */ './NavbarSearch');
    const WishlistItemNavbar = () => import( /* webpackChunkName: "navbar-wishlist" */ './WishlistItemNavbar');
    const DesktopMegaMenu = () => import( /* webpackChunkName: "navbar-mega-menu" */ './DesktopMegaMenu');

    export default {
        name: 'DesktopNavbar',
        components: {
            CartItemNavbar,
            WishlistItemNavbar,
            NavbarSearch,
            AuthItemNavbar,
            LanguageItemNavbar,
            DesktopMegaMenu
        },
        data() {
            return {
                megaMenuIsActive: false,
                megaMenuToDeactivate: false,
                hover: {
                    active: false,
                    index: 1,
                },
                cursor: {
                    x: 0,
                    y: 0,
                },
                sublineElement: {
                    opened: false,
                    width: '0px',
                    left: '0px',
                },
            };
        },
        computed: {
            ...mapState('general', ['isCustomHAF']),
        },

        methods: {
            activateMegaMenu() {
                this.megaMenuIsActive = true;
                this.megaMenuToDeactivate = false;
                this.subline(null, 'full');
            },
            deactivateMegaMenu() {
                this.megaMenuToDeactivate = true;
                setTimeout(() => {
                    if (this.megaMenuToDeactivate) {
                        this.megaMenuIsActive = false;
                    }
                }, 200);
            },
            subline(event, size) {
                if (size == 'full') {
                    this.sublineElement.left = '0px';
                    this.sublineElement.width = '100%';
                } else {
                    this.sublineElement.left = event.target.offsetLeft + 'px';
                    this.sublineElement.width = event.target.offsetWidth + 'px';
                }

                if (!this.sublineElement.opened) {
                    setTimeout(() => {
                        this.sublineElement.opened = true;
                    }, 20);
                }
            },
            resetSubline() {
                this.sublineElement.width = '0px';
                this.sublineElement.left = '0px';
                this.sublineElement.opened = false;
            },
        },
        watch: {
            '$route.params'() {
                this.megaMenuIsActive = false;
                this.resetSubline();
            },
        },
    };
</script>
